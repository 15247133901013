import 'bootstrap/dist/css/bootstrap.min.css';
import '../styles/scss/common.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../styles/scss/frontend_user/app.scss';
import '../styles/globals.css';
import { useRouter } from 'next/router'
import { GoogleAnalytics } from "nextjs-google-analytics";

function MyApp({ Component, pageProps }) {

   const Layout = Component.Layout
   const router = useRouter();

    if(router.isFallback) {
            return <div id="spinner" className="spinner_req_review">
                  <div className="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                </div> 
    }else {
        return <><Layout><GoogleAnalytics trackPageViews /><Component {...pageProps} /></Layout></>
    }

}

export default MyApp
